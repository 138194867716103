// @use "sass:map";
@use "@angular/material" as mat;
/* You can add global styles to this file, and also import other style files */
@import "assets/md-font/material-icons.css";

/* Select Angular Material theming. */
// @import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "./podbot-theme.scss";

@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-balham.css";

body {
    font-family: Roboto, Arial, sans-serif;
    margin: 2%;
}

h3 {
	font-family: Roboto, Arial, sans-serif;
	font-size: 16px;
	font-weight: bold;
	margin-left: 2%
}

h2 {
	font-size: 18px;
}

fieldset {
	border: 0px;
	padding: 0px;
}

.normal-text {
	font-family: Roboto, Arial, sans-serif;
	font-size: 14px;
	margin-left: 2%;
}

.big-text {
	font-family: Roboto, Arial, sans-serif;
	font-size: 23px;
	font-weight: bold;
}

.form-field {
	display: block;
	border: 1px solid #999;
	height: 25px;
	background-color: white;
}

.right-align {
	margin: 0 auto;
	flex: 1 1 auto;
	text-align: right;
}

/* Containers */

.pbp-outer-container {
	display: inline-block;
	width: 100%;
}

.pbp-display-none {
	display: none;
	width: 100%;
}

.pbp-container {
	min-width: 30%;
	max-width: 85%;
	margin: 0% 2% 2% 0%;
	display: inline-block;
	vertical-align: top;
}

.pbp-banner {
	width: 100%;
	margin: 1% 0% 1.5% 0%;
	vertical-align: middle;
	text-align: center;
}

.pbp-list {
	border: solid 1px #ccc;
	min-height: 41px;
	background: white;
	border-radius: 4px;
	overflow: hidden;
	display: block;
	margin: 3%;
	width: 100%;
}

.pbp-list-header {
	margin-left: 3%;
	font-size: larger;
	font-weight: bold;
}

.pbp-box {
	padding: 4% 4%;
	border-bottom: solid 1px #ccc;
	color: rgba(0, 0, 0, 0.87);
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	box-sizing: border-box;
	cursor: move;
	background: white;
	font-size: 14px;
}

.pbp-box-selected {
	background: mat.get-color-from-palette($podbot-primary, 'default');
	color: mat.get-color-from-palette($podbot-primary, 'default-contrast');
}

.pbp-audio {
	min-width: 28%;
	max-width: 28%;
	margin-left: 1%;
}

.pbp-audio-player {
	width: 100%;
	margin: 3% 0% 3% 0%;
}

.pbp-box:last-child {
	border: none;
}

@media screen and (max-width: 900px) {
	.pbp-outer-container {
		display: flex;
		flex-flow: column;
	}

	.pbp-container {
		margin: 0%, 1%, 1% 0%;
	}

	.pbp-library {
		order: 3;
	}

	.pbp-playlist {
		order: 2;
	}

	.pbp-audio {
		order: 1;
		max-width: 85%;
	}

	.pbp-box {
		padding: 3% 3%;
	}
}

/* Angular CDK */

.pbp-list.cdk-drop-list-dragging .pbp-box:not(.cdk-drag-placeholder) {
	transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-preview {
	box-sizing: border-box;
	border-radius: 4px;
	box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
	opacity: 0;
}

.cdk-drag-animating {
	transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
